
import './App.css';
import Header from './Components/Header/Header';
import T from './Components/T';


function App() {
  return (
    <>
    <Header/>
    <T/>
   </>
  );
}

export default App;
