import React from 'react'
import Footer from "./Footer/Footer"
const T = () => {
  return (
    <>
    <div style={{width:"100vw",height:"100vh",backgroundImage:`url(Tdimages/banner.jpg)`,backgroundSize:"cover",backgroundRepeat:"no-repeat",textAlign:"center"}}>
    <div className="main" >
     <div className="contact">
        <h1 className="title titles">Tourdeals coming soon...</h1>
        <h2 className="sub-title titles">Site Under Development</h2>
      </div>
    <div className='imgmain'>
    <h1 className="title1">Your travel deals haven</h1>
      
      </div>
    </div>
<div className="footercontainer">
<Footer/>
</div>  
</div>
  </>
  )
}

export default T