import React from 'react'
import "./Header.css"
const Header = () => {
  return (
    <div className='div1'>
     <img className='logo' src="Tdimages/Tourdealslogo.png" alt="" />   
    </div>
  )
}

export default Header